body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-prev:before, 
.slick-next:before {
    color: red!important; /* replace with your desired color */
}

.carousel .slide img {
  max-height: "50vh"!important; /* adjust to desired max height */
  width: auto!important; /* adjust width proportionally */
  margin: auto; /* center the image */
}
